import { isObject } from "../utils/is-object.js";
import { HOUR_TO_MS, MINUTE_TO_MS, SECOND_TO_MS } from "./time-constants.js";
export class PosixDate {
    timestamp;
    constructor(timestamp) {
        this.timestamp = timestamp;
    }
    getTimestamp() {
        return this.timestamp;
    }
    toJSON() {
        return {
            type: "posix-date",
            timestamp: this.timestamp,
        };
    }
    static fromJson(json) {
        return new PosixDate(json.timestamp);
    }
    static isPosixDateJSON(value) {
        if (isObject(value) &&
            "type" in value &&
            "timestamp" in value &&
            value["type"] === "posix-date" &&
            typeof value["timestamp"] === "number")
            return true;
        return false;
    }
    toLocaleString(opts, locale = "en-US") {
        const currentTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return new Date(this.timestamp).toLocaleString(locale, {
            timeZone: currentTimezone,
            ...opts,
        });
    }
    isAfter(date) {
        return this.timestamp > date.getTimestamp();
    }
    isBefore(date) {
        return this.timestamp < date.getTimestamp();
    }
    isEqualTo(date) {
        return this.timestamp === date.getTimestamp();
    }
    addHours(hours) {
        return new PosixDate(this.timestamp + hours * HOUR_TO_MS);
    }
    addDays(days) {
        return new PosixDate(this.timestamp + days * HOUR_TO_MS * 24);
    }
    addMinutes(minutes) {
        return new PosixDate(this.timestamp + minutes * MINUTE_TO_MS);
    }
    addSeconds(seconds) {
        return new PosixDate(this.timestamp + seconds * SECOND_TO_MS);
    }
    subtractDays(days) {
        return new PosixDate(this.timestamp - days * HOUR_TO_MS * 24);
    }
    subtractHours(hours) {
        return new PosixDate(this.timestamp - hours * HOUR_TO_MS);
    }
    subtractMinutes(minutes) {
        return new PosixDate(this.timestamp - minutes * MINUTE_TO_MS);
    }
    subtractSeconds(seconds) {
        return new PosixDate(this.timestamp - seconds * SECOND_TO_MS);
    }
    getDateString() {
        return new Date(this.timestamp).toISOString().split("T")[0];
    }
    getTimeString() {
        return new Date(this.timestamp)
            .toISOString()
            .split("T")[1]
            .split(".")[0];
    }
}
