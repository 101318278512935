import { UUID } from "@for-it/domain-lib";
import { AppRoutes } from "@for-it/web-lib";
import { Permission } from "app-domain";
import { lazy } from "react";
import HomeLayout from "./layouts/home.tsx";
/*
 *  In this file, we define all the routes that the frontend will use.
 *  We do this for a few reasons:
 *      - To have a single source of truth for all the routes in the frontend. This allows us to easily change the routes in the future without braking any links in the application
 *     - To configure permissions for each route. This allows us to easily restrict access to certain routes based on the current user's permissions
 *      - To only enable pages that are defined in the routes. This allows us to easily disable pages that are not yet implemented or pages that are not yet ready to be shown to the user
 *
 *  Note 1: that we do not define the actual components that are shown on the routes here. We only define the layout that is shown on the route, and the pages are automatically loaded based on the path in the pages directory. This means that "/login", por example, will automatically load the component from "pages/login.tsx". If there is no page for a route, the app will not start and will throw an error.
 *  Note 2: Some layouts are loaded as dynamic imports (same as the routes) to reduce the bundle size. This means that the layout is only loaded when the route is accessed, and not when the app is loaded.
 *  Note 3: The paths are defined as functions that return the path. This is done so we can call these paths with parameters (e.g./user/:id) and the path will be correctly generated. This is useful for generating links in the application (e.g. <Link to={routes.USER.path({ id: 1 })}>User 1</Link>. On the other hand, if we don't pass any parameters, the path will continue to be the template string (e.g. /user/:id).
 */

// ======== LAZY LOADED LAYOUTS ========
const adminLayout = lazy(() => import("./layouts/admin-panel.tsx"));

// ======== ROUTES ========
export const Routes = {
    HOME: {
        path: () => "/",
        layout: HomeLayout,
    },
    LOGIN: {
        path: () => "/login",
        layout: HomeLayout,
    },
    REGISTER: {
        path: () => "/register",
        layout: HomeLayout,
    },
    ADMIN_DASHBOARD: {
        path: () => "/admin",
        isAuthRequired: true,
        requiredPermissions: [Permission.VIEW_ADMIN_DASHBOARD],
        layout: adminLayout,
    },
    ADMIN_BLOG_DETAIL: {
        path: (id?: string) => `/admin/blogs/${id ?? "[id]"}/details`,
        isAuthRequired: true,
        requiredPermissions: [Permission.GET_BLOG_DETAILS],
        layout: adminLayout,
    },
    ADMIN_LIST_COURSES: {
        path: () => `/admin/courses/list`,
        isAuthRequired: true,
        requiredPermissions: [Permission.LIST_ALL_COURSES],
        layout: adminLayout,
    },
    ADMIN_LIST_USERS: {
        path: () => `/admin/users/list`,
        isAuthRequired: true,
        requiredPermissions: [Permission.GET_USER_LIST],
        layout: adminLayout,
    },
    ADMIN_LIST_TEACHERS: {
        path: () => `/admin/teachers/list`,
        isAuthRequired: true,
        requiredPermissions: [Permission.GET_TEACHER_LIST],
        layout: adminLayout,
    },
    ADMIN_TEACHERS_DETAILS: {
        path: (id?: string) => `/admin/teachers/${id ?? "[id]"}/details`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_BLOG_LIST: {
        path: () => `/admin/blogs/list`,
        isAuthRequired: true,
        requiredPermissions: [Permission.LIST_ALL_BLOGS],
        layout: adminLayout,
    },
    ADMIN_CREATE_COURSE: {
        path: (type?: string) =>
            `/admin/courses/create${type ? "?type=" + type : ""}`,
        isAuthRequired: true,
        requiredPermissions: [Permission.CREATE_COURSE],
        layout: adminLayout,
    },
    ADMIN_EDIT_COURSE: {
        path: (id?: string, type?: string) =>
            `/admin/courses/${id ?? "[id]"}/edit${type ? "?type=" + type : ""}`,
        isAuthRequired: true,
        requiredPermissions: [Permission.EDIT_COURSE],
        layout: adminLayout,
    },
    WAITLIST: {
        path: (id?: string) => `/courses/${id ?? "[id]"}/waitlist`,
        isAuthRequired: true,
        layout: HomeLayout,
    },
    PICK_COHORT: {
        path: (id?: string) => `/courses/${id ?? "[id]"}/cohort/pick-cohort`,
        isAuthRequired: true,
        layout: HomeLayout,
    },
    LIST_MY_COURSE: {
        path: () => `/my-courses`,
        isAuthRequired: true,
        layout: HomeLayout,
    },
    DETAIL_MY_COURSE: {
        path: (courseId?: UUID, type?: string, unitId?: string) =>
            `/my-courses/${courseId ?? "[courseId]"}${type ? "?type=" + type : ""}${unitId ? "&unitId=" + unitId : ""}`,
        isAuthRequired: true,
        layout: HomeLayout,
    },
    COURSE_INFO: {
        path: (courseId?: UUID, type?: string) =>
            `/courses/${courseId ?? "[id]"}/detail${type ? "?type=" + type : ""}`,
        //isAuthRequired: true,
        layout: HomeLayout,
    },
    ADMIN_COHORT_DETAILS: {
        path: (courseId?: string, id?: string) =>
            `/admin/courses/${courseId ?? "[id]"}/cohorts/${id ?? "[cohortId]"}/details`,
        isAuthRequired: true,
        requiredPermissions: [Permission.GET_COHORT_DETAILS],
        layout: adminLayout,
    },
    PAYMENT: {
        path: (courseId?: UUID, cohortId?: UUID) =>
            `/courses/${courseId ?? "[id]"}/cohort/${cohortId ?? "[cohortId]"}/payment`,
        isAuthRequired: true,
        layout: HomeLayout,
    },
    ADMIN_CREATE_BLOG: {
        path: () => "/admin/blogs/create",
        isAuthRequired: true,
        requiredPermissions: [Permission.CREATE_BLOG],
        layout: adminLayout,
    },
    ADMIN_CREATE_COHORT: {
        path: (id?: string) => `/admin/courses/${id ?? "[id]"}/cohorts/create`,
        isAuthRequired: true,
        requiredPermissions: [Permission.SCHEDULE_NEW_COHORT],
        layout: adminLayout,
    },
    ADMIN_SYNC_MODULE_DETAILS: {
        path: (courseId?: string, cohortId?: UUID, id?: UUID) =>
            `/admin/courses/${courseId ?? "[id]"}/cohorts/${cohortId ?? "[cohortId]"}/modules/${id ?? "[moduleId]"}/details`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    LIST_COURSES: {
        path: () => "/courses",
        layout: HomeLayout,
    },
    PROFILE: {
        path: (id?: string) => `/user/${id ?? "[id]"}/profile`,
        isAuthRequired: true,
        layout: HomeLayout,
    },
    ADMIN_EDIT_BLOG: {
        path: (id?: string) => `/admin/blogs/${id ?? "[id]"}/edit-blog`,
        isAuthRequired: true,
        requiredPermissions: [Permission.EDIT_BLOG],
        layout: adminLayout,
    },
    BLOG_DETAILS: {
        path: (id?: string) => `/blog/${id ?? "[id]"}/blog`,
        isAuthRequired: false,
        layout: HomeLayout,
    },
    ADMIN_CREATE_SYNC_MODULE: {
        path: (id?: string, cohortId?: string) =>
            `/admin/courses/${id ?? "[id]"}/cohorts/${cohortId ?? "[cohortId]"}/modules/create`,
        isAuthRequired: true,
        requiredPermissions: [Permission.CREATE_SYNC_MODULE],
        layout: adminLayout,
    },
    ADMIN_EDIT_SYNC_MODULE: {
        path: (id?: string, cohortId?: string, moduleId?: string) =>
            `/admin/courses/${id ?? "[id]"}/cohorts/${cohortId ?? "[cohortId]"}/modules/${moduleId ?? "[moduleId]"}/edit`,
        isAuthRequired: true,
        requiredPermissions: [Permission.EDIT_SYNC_MODULE],
        layout: adminLayout,
    },

    ADMIN_CREATE_ASYNC_MODULE: {
        path: (id?: string) => `/admin/courses/${id ?? "[id]"}/modules/create`,
        isAuthRequired: true,
        requiredPermissions: [Permission.CREATE_ASYNC_MODULE],
        layout: adminLayout,
    },
    ADMIN_EDIT_ASYNC_MODULE: {
        path: (id?: string, moduleId?: string) =>
            `/admin/courses/${id ?? "[id]"}/modules/${moduleId ?? "[moduleId]/edit"}`,
        isAuthRequired: true,
        requiredPermissions: [Permission.EDIT_ASYNC_MODULE],
        layout: adminLayout,
    },
    ADMIN_ASYNC_MODULE_DETAILS: {
        path: (id?: string, moduleId?: string) =>
            `/admin/courses/${id ?? "[id]"}/modules/${moduleId ?? "[moduleId]"}/details`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_ASYNC_UNIT_DETAILS: {
        path: (id?: string, moduleId?: string, unitId?: string) =>
            `/admin/courses/${id ?? "[id]"}/modules/${moduleId ?? "[moduleId]"}/async-unit/${unitId ?? "[unitId]"}/detail`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_SYNC_UNIT_DETAILS: {
        path: (
            id?: string,
            cohortId?: string,
            moduleId?: string,
            unitId?: string,
        ) =>
            `/admin/courses/${id ?? "[id]"}/cohorts/${cohortId ?? "[cohortId]"}/modules/${moduleId ?? "[moduleId]"}/sync-unit/${unitId ?? "[unitId]"}/detail`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_CREATE_ASYNC_UNIT: {
        path: (id?: string, moduleId?: string) =>
            `/admin/courses/${id ?? "[id]"}/modules/${moduleId ?? "[moduleId]"}/async-unit/create`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_EDIT_ASYNC_UNIT: {
        path: (id?: string, moduleId?: string, unitId?: string) =>
            `/admin/courses/${id ?? "[id]"}/modules/${moduleId ?? "[moduleId]"}/async-unit/${unitId ?? "[unitId]"}/edit`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_CREATE_SYNC_UNIT: {
        path: (id?: string, cohortId?: string, moduleId?: string) =>
            `/admin/courses/${id ?? "[id]"}/cohorts/${cohortId ?? "[cohortId]"}/modules/${moduleId ?? "[moduleId]"}/sync-unit/create`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_EDIT_SYNC_UNIT: {
        path: (
            id?: string,
            cohortId?: string,
            moduleId?: string,
            unitId?: string,
        ) =>
            `/admin/courses/${id ?? "[id]"}/cohorts/${cohortId ?? "[cohortId]"}/modules/${moduleId ?? "[moduleId]"}/sync-unit/${unitId ?? "[unitId]"}/edit`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_COURSE_DETAILS: {
        path: (id?: string, type?: string) =>
            `/admin/courses/${id ?? "[id]"}/details${type ? "?type=" + type : ""}`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_CREATE_TEACHER: {
        path: () => `/admin/teachers/create`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_EDIT_TEACHER: {
        path: (id?: string) => `/admin/teachers/${id ?? "[id]"}/edit`,
        isAuthRequired: true,
        layout: adminLayout,
    },
    ADMIN_USER_DETAILS: {
        path: (id?: string) => `/admin/users/${id ?? "[id]"}`,
        layout: adminLayout,
        isAuthRequired: true,
        requiredPermissions: [Permission.GET_USER_DETAILS],
    },
    BLOG: {
        path: () => `/blog`,
        isAuthRequired: false,
        layout: HomeLayout,
    },
    NEWSLETTER: {
        path: () => `/newsletter`,
        isAuthRequired: false,
        layout: HomeLayout,
    },
    FINISH_REGISTRATION: {
        path: (token?: string, email?: string) =>
            `/finish-registration${token && email ? "?token=" + token + "&email=" + email : ""}`,
        isAuthRequired: false,
        layout: HomeLayout,
    },
    SYNC_ENROLL: {
        path: (courseId?: string, cohortId?: string) =>
            `/courses/${courseId ?? "[id]"}/cohort/${cohortId ?? "[cohortId]"}/enroll`,
        isAuthRequired: true,
        layout: HomeLayout,
    },
    ASYNC_ENROLL: {
        path: (courseId?: string) => `/courses/${courseId ?? "[id]"}/enroll`,
        isAuthRequired: true,
        layout: HomeLayout,
    },
    ABOUT_US: {
        path: () => `/about-us`,
        isAuthRequired: false,
        layout: HomeLayout,
    },
    CONTACT: {
        path: () => `/contact`,
        isAuthRequired: false,
        layout: HomeLayout,
    },
    CONSULTANCY: {
        path: () => `/consultancy`,
        isAuthRequired: false,
        layout: HomeLayout,
    },
} as const satisfies AppRoutes<Permission>;

export type Routes = typeof Routes;
