import { TaggedError, } from "@for-it/domain-lib";
export class CourseNameInUseError extends TaggedError {
    constructor() {
        super("CourseNameInUseError");
    }
}
export class MissingChoicesError extends TaggedError {
    constructor() {
        super("MissingChoicesError");
    }
}
