/* eslint-disable @typescript-eslint/no-explicit-any */
import { TaggedError } from "../errors/tagged-error.js";
import { isRecordEmpty } from "../utils/is-record-empty.js";
/**
 * Creates a validation schema from a map of field validators.
 */
export function createValidationSchema(schema) {
    return (value) => {
        const errors = {};
        const result = {};
        for (const key in schema) {
            const validationResult = schema[key](value[key]);
            if (!validationResult.isValid) {
                errors[key] = validationResult.error;
            }
            else {
                result[key] = validationResult.value;
            }
        }
        if (!isRecordEmpty(errors)) {
            return new ValidationError(errors, result);
        }
        else {
            return result;
        }
    };
}
export class ValidationError extends TaggedError {
    errors;
    value;
    constructor(errors, value) {
        super("ValidationError");
        this.errors = errors;
        this.value = value;
    }
}
