import { PropsWithChildren } from "react";
import { Footer } from "../components/page-landing/footer.tsx";
import { Header } from "../components/page-landing/header.tsx";

export default function HomeLayout({ children }: PropsWithChildren) {
    return (
        <>
            <Header />
            {children}
            <Footer />
        </>
    );
}
