import { isError } from "@for-it/domain-lib";
import { useAuthState } from "@for-it/web-lib";
import { GetMyCourseViewModel } from "app-domain";
import { UUID } from "crypto";
import { PropsWithChildren, useEffect, useState } from "react";
import { useAPI } from "../utils/rpc-hooks.ts";
import { CoursesUserContext } from "./courses-user-context.js";

export function CoursesUserProvider({ children }: PropsWithChildren) {
    const api = useAPI();
    const AuthState = useAuthState();
    const [coursesUser, setCoursesUser] = useState<GetMyCourseViewModel[]>([]);

    const fetchData = async (id: UUID) => {
        const result = await api.call("getMyCourses", { id });
        if (!isError(result)) {
            setCoursesUser(result);
        }
    };

    useEffect(() => {
        if (AuthState.isLoggedIn && AuthState.currentUser) {
            fetchData(AuthState.currentUser.id);
        }
    }, []);

    return (
        <CoursesUserContext.Provider value={coursesUser}>
            {children}
        </CoursesUserContext.Provider>
    );
}
