import { EmailAlreadyValidatedError, EmailInUseError, EntityAlreadyExistsError, InvalidCredentialsError, InvalidEmailValidationTokenError, InvalidFileTypeError, NotFoundError, RateLimitError, UnexpectedError, ValidationError, } from "@for-it/domain-lib";
import { CourseNameInUseError, MissingChoicesError, } from "./services/course-service.js";
export const DomainErrors = {
    UnexpectedError: UnexpectedError,
    InvalidCredentialsError: InvalidCredentialsError,
    EmailInUseError: EmailInUseError,
    EntityAlreadyExistsError: EntityAlreadyExistsError,
    NotFoundError: NotFoundError,
    RateLimitError: RateLimitError,
    EmailAlreadyValidatedError: EmailAlreadyValidatedError,
    InvalidEmailValidationTokenError: InvalidEmailValidationTokenError,
    ValidationError: ValidationError,
    CourseNameInUseError: CourseNameInUseError,
    MissingChoicesError: MissingChoicesError,
    InvalidFileTypeError: InvalidFileTypeError,
};
