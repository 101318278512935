import { DefaultPermissions, DefaultUserRole } from "@for-it/domain-lib";
import { UserRole } from "./user-role.js";

export const Permission = {
    ...DefaultPermissions,
    CREATE_COURSE: "CREATE_COURSE",
    DELETE_COURSE: "DELETE_COURSE",
    VIEW_COHORT_STUDENTS: "VIEW_COHORT_STUDENTS",
    GET_USER_DETAILS: "GET_USER_DETAILS",
    GET_USER_LIST: "GET_USER_LIST",
    EDIT_COURSE: "EDIT_COURSE",
    LIST_ALL_COURSES: "LIST_ALL_COURSES",
    VIEW_COURSE_DETAILS: "VIEW_COURSE_DETAILS",
    SCHEDULE_NEW_COHORT: "SCHEDULE_NEW_COHORT",
    EDIT_COHORT: "EDIT_COHORT",
    DELETE_COHORT: "DELETE_COHORT",
    GET_BLOG_DETAILS: "GET_BLOG_DETAILS",
    LIST_ALL_BLOGS: "LIST_ALL_BLOGS",
    EDIT_BLOG: "EDIT_BLOG",
    CREATE_BLOG: "CREATE_BLOG",
    DELETE_BLOG: "DELETE_BLOG",
    GET_AVAILABLE_COHORTS: "GET_AVAILABLE_COHORTS",
    EDIT_ASYNC_MODULE: "EDIT_ASYNC_MODULE",
    CREATE_SYNC_UNIT: "CREATE_SYNC_UNIT",
    EDIT_SYNC_UNIT: "EDIT_SYNC_UNIT",
    DELETE_SYNC_UNIT: "DELETE_SYNC_UNIT",
    LIST_ASYNC_MODULES: "LIST_ASYNC_MODULES",
    DELETE_ASYNC_MODULE: "DELETE_ASYNC_MODULE",
    CREATE_ASYNC_MODULE: "CREATE_ASYNC_MODULE",
    UPDATE_MODULE_ORDER: "UPDATE_MODULE_ORDER",
    EDIT_SYNC_MODULE: "EDIT_SYNC_MODULE",
    DELETE_SYNC_MODULE: "DELETE_SYNC_MODULE",
    CREATE_SYNC_MODULE: "CREATE_SYNC_MODULE",
    EDIT_ASYNC_UNIT: "EDIT_ASYNC_UNIT",
    CREATE_ASYNC_UNIT: "CREATE_ASYNC_UNIT",
    DELETE_ASYNC_UNIT: "DELETE_ASYNC_UNIT",
    GET_ASYNC_MODULE_DETAILS: "GET_ASYNC_MODULE_DETAILS",
    UPDATE_UNIT_ORDER: "UPDATE_UNIT_ORDER",
    DELETE_ASYNC_COURSE: "DELETE_ASYNC_COURSE",
    GET_ASYNC_COURSE_DETAILS: "GET_ASYNC_COURSE_DETAILS",
    LIST_ASYNC_COURSES: "LIST_ASYNC_COURSES",
    CREATE_ASYNC_COURSE: "CREATE_ASYNC_COURSE",
    EDIT_ASYNC_COURSE: "EDIT_ASYNC_COURSE",
    LIST_LEGENDS: "LIST_LEGENDS",
    CREATE_LEGENDS: "CREATE_LEGENDS",
    DELETE_LEGENDS: "DELETE_LEGENDS",
    GET_TEACHER_LIST: "GET_TEACHER_LIST",
    GET_TEACHER_DETAILS: "GET_TEACHERS_DETAILS",
    CREATE_TEACHER: "CREATE_TEACHER",
    DELETE_TEACHER: "DELETE_TEACHER",
    EDIT_TEACHER: "EDIT_TEACHER",
    VIEW_ADMIN_DASHBOARD: "VIEW_ADMIN_DASHBOARD",
    GET_COHORT_DETAILS: "GET_COHORT_DETAILS",
    GET_CONFIG: "GET_CONFIG",
    SET_CONFIG: "SET_CONFIG",
    DETAIL_ASYNC_UNIT: "DETAIL_ASYNC_UNIT",
    DETAIL_SYNC_UNIT: "DETAIL_SYNC_UNIT",
    GET_MY_ASYNC_COURSE_ENROLLED: "GET_MY_ASYNC_COURSE_ENROLLED",
    GET_MY_DETAIL_ASYNC_UNIT: "GET_MY_DETAIL_ASYNC_UNIT",
    GET_MY_COURSE_ENROLLED: "GET_MY_ASYNC_COURSE_ENROLLED",
    GET_MY_DETAIL_SYNC_UNIT: "GET_MY_DETAIL_ASYNC_UNIT",
    MARK_ASYNC_UNIT: "MARK_ASYNC_UNIT",
    GET_MY_LIST_ASYNC_MODULE: "GET_MY_LIST_ASYNC_MODULE",
    GET_MY_LIST_SYNC_MODULE: "GET_MY_LIST_SYNC_MODULE",
} as const;

export type Permission = (typeof Permission)[keyof typeof Permission];

export const PermissionsByRole = {
    [DefaultUserRole.ADMIN]: [
        Permission.CREATE_COURSE,
        Permission.DELETE_COURSE,
        Permission.EDIT_COURSE,
        Permission.LIST_ALL_COURSES,
        Permission.VIEW_COURSE_DETAILS,
        Permission.SCHEDULE_NEW_COHORT,
        Permission.VIEW_COHORT_STUDENTS,
        Permission.EDIT_COHORT,
        Permission.DELETE_COHORT,
        Permission.GET_USER_LIST,
        Permission.GET_USER_DETAILS,
        Permission.GET_BLOG_DETAILS,
        Permission.LIST_ALL_BLOGS,
        Permission.EDIT_BLOG,
        Permission.CREATE_BLOG,
        Permission.DELETE_BLOG,
        Permission.GET_AVAILABLE_COHORTS,
        Permission.EDIT_ASYNC_MODULE,
        Permission.CREATE_SYNC_UNIT,
        Permission.EDIT_SYNC_UNIT,
        Permission.DELETE_SYNC_UNIT,
        Permission.EDIT_ASYNC_MODULE,
        Permission.CREATE_SYNC_UNIT,
        Permission.EDIT_SYNC_UNIT,
        Permission.DELETE_SYNC_UNIT,
        Permission.LIST_ASYNC_MODULES,
        Permission.DELETE_ASYNC_MODULE,
        Permission.CREATE_ASYNC_MODULE,
        Permission.UPDATE_MODULE_ORDER,
        Permission.EDIT_SYNC_MODULE,
        Permission.DELETE_SYNC_MODULE,
        Permission.CREATE_SYNC_MODULE,
        Permission.LIST_ASYNC_MODULES,
        Permission.EDIT_ASYNC_UNIT,
        Permission.CREATE_ASYNC_UNIT,
        Permission.DELETE_ASYNC_UNIT,
        Permission.GET_ASYNC_MODULE_DETAILS,
        Permission.UPDATE_UNIT_ORDER,
        Permission.DELETE_ASYNC_COURSE,
        Permission.GET_ASYNC_COURSE_DETAILS,
        Permission.LIST_ASYNC_COURSES,
        Permission.CREATE_ASYNC_COURSE,
        Permission.EDIT_ASYNC_COURSE,
        Permission.LIST_LEGENDS,
        Permission.CREATE_LEGENDS,
        Permission.DELETE_LEGENDS,
        Permission.GET_TEACHER_DETAILS,
        Permission.GET_TEACHER_LIST,
        Permission.DELETE_TEACHER,
        Permission.EDIT_TEACHER,
        Permission.CREATE_TEACHER,
        Permission.EDIT_TEACHER,
        Permission.VIEW_ADMIN_DASHBOARD,
        Permission.GET_COHORT_DETAILS,
        Permission.GET_CONFIG,
        Permission.SET_CONFIG,
        Permission.DETAIL_ASYNC_UNIT,
        Permission.DETAIL_ASYNC_UNIT,
    ],
    [DefaultUserRole.REGULAR]: [
        Permission.GET_MY_ASYNC_COURSE_ENROLLED,
        Permission.GET_MY_DETAIL_ASYNC_UNIT,
        Permission.GET_MY_COURSE_ENROLLED,
        Permission.GET_MY_DETAIL_SYNC_UNIT,
        Permission.GET_MY_LIST_ASYNC_MODULE,
        Permission.GET_MY_LIST_SYNC_MODULE,
        Permission.MARK_ASYNC_UNIT,
        Permission.GET_AVAILABLE_COHORTS,
        Permission.VIEW_COURSE_DETAILS,
    ],
} as const satisfies Record<UserRole, Permission[]>;
