import { Anchor } from "@for-it/web-lib";
import { Routes } from "../../routes.tsx";

export function Footer() {
    return (
        <div className="flex bg-custom-black w-full justify-center">
            <div className="container flex flex-col px-10 py-10">
                <div className="lg:flex">
                    <div className="flex flex-col items-center lg:items-start lg:w-6/12">
                        <img
                            src="/page-landing/logoEstacion.svg"
                            alt="logo"
                            className="w-56 mb-7"
                        />
                        <p className="font-ubuntu-regular text-white text-center lg:text-start px-3 lg:px-0 w-80 lg:w-7/12 text-xs lg:text-base mb-9">
                            Lorem ipsum dolor sit amet, cons ectetuer adipiscing
                            elit, sed do. Lorem ipsum dolor sit amet
                        </p>
                        <div className="border-b border-white w-full lg:w-6/12"></div>

                        <div className="flex flex-row gap-5 py-5 lg:pl-3">
                            <img
                                src="/page-landing/social-linkedin.svg"
                                alt=""
                                className="lg:w-5"
                            />
                            <img
                                src="/page-landing/social-twitter.svg"
                                alt=""
                                className="lg:w-5"
                            />
                            <img
                                src="/page-landing/social-instagram.svg"
                                alt=""
                                className="lg:w-5"
                            />
                        </div>
                    </div>

                    <div className="flex flex-col items-center lg:items-start gap-2 lg:w-3/12">
                        <p className="font-ubuntu-bold text-base lg:text-xl text-white">
                            Consultas
                        </p>
                        <div className="flex flex-row gap-2">
                            <img
                                src="/page-landing/social-whatsapp.svg"
                                alt=""
                            />
                            <p className="text-white font-ubuntu-regular text-sm">
                                +5491135378899
                            </p>
                        </div>
                        <div className="flex flex-row gap-2">
                            <img
                                src="/page-landing/social-youtube.svg"
                                alt=""
                            />
                            <p className="text-white font-ubuntu-regular text-sm">
                                estacionr@gmail.com
                            </p>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2 items-center lg:items-start py-8 lg:py-0 lg:w-3/12">
                        <p className="text-white font-ubuntu-bold text-base lg:text-xl">
                            Links
                        </p>
                        <Anchor
                            url={Routes.ABOUT_US.path()}
                            className="text-white font-ubuntu-bold lg:font-ubuntu text-sm "
                        >
                            Quienes Somos
                        </Anchor>
                        <Anchor
                            url={Routes.LIST_COURSES.path()}
                            className="text-white font-ubuntu-bold lg:font-ubuntu text-sm"
                        >
                            Cursos
                        </Anchor>
                        <Anchor
                            url=""
                            className="text-white font-ubuntu-bold lg:font-ubuntu text-sm"
                        >
                            Análisis y procesamiento con R
                        </Anchor>
                        <Anchor
                            url=""
                            className="text-white font-ubuntu-bold lg:font-ubuntu text-sm"
                        >
                            Python para principiantes
                        </Anchor>
                        <Anchor
                            url={Routes.CONTACT.path()}
                            className="text-white font-ubuntu-bold lg:font-ubuntu text-sm"
                        >
                            Contacto
                        </Anchor>
                        <Anchor
                            url=""
                            className="text-white font-ubuntu-bold lg:font-ubuntu text-sm"
                        >
                            Consultoría
                        </Anchor>
                        <Anchor
                            url={Routes.NEWSLETTER.path()}
                            className="text-white font-ubuntu-bold lg:font-ubuntu text-sm"
                        >
                            Newsletter
                        </Anchor>
                    </div>
                </div>

                <div className="flex justify-center pt-8 border-t border-white lg:mt-20">
                    <p className="text-white font-ubuntu-regular text-xs lg:text-base">
                        Desarrollado por For it y La Empujadora
                    </p>
                </div>
            </div>
        </div>
    );
}
