import { PosixDate } from "../time/posix-date.js";
export var JSONUtils;
(function (JSONUtils) {
    function reviver(key, value) {
        if (PosixDate.isPosixDateJSON(value)) {
            return PosixDate.fromJson(value);
        }
        return value;
    }
    JSONUtils.reviver = reviver;
    function parse(json) {
        return JSON.parse(json, reviver);
    }
    JSONUtils.parse = parse;
})(JSONUtils || (JSONUtils = {}));
