import { TaggedError } from "../errors/tagged-error.js";
import { HOUR_TO_MS } from "../time/time-constants.js";
import { exponentialBackoff } from "./exponential-backoff.js";
export class RateLimitError extends TaggedError {
    timeout;
    attempts;
    constructor(timeout, attempts) {
        super("RateLimitError");
        this.timeout = timeout;
        this.attempts = attempts;
    }
}
export async function assertRateLimit(attempts, lastTryAt, baseDelay = 100, maxDelay = HOUR_TO_MS * 24) {
    const prevTimeTimeout = exponentialBackoff(attempts - 1, baseDelay, maxDelay);
    if (Date.now() - lastTryAt.getTimestamp() > prevTimeTimeout) {
        return;
    }
    return new RateLimitError(exponentialBackoff(attempts, baseDelay, maxDelay), attempts);
}
