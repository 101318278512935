import { isError } from "@for-it/domain-lib";
import { Anchor, useCurrentUser, useLogout } from "@for-it/web-lib";
import { Permission } from "app-domain/src/auth/permissions.ts";
import { Routes } from "../routes.tsx";
import { useNavigate } from "../utils/routing-hooks.ts";

export function Menu() {
    const currentUser = useCurrentUser();

    const logOut = useLogout();
    const navigate = useNavigate();

    async function onLogOut() {
        const result = await logOut();
        if (isError(result)) {
            return result;
        } else {
            navigate.toUrl(Routes.HOME.path());
        }
    }

    return (
        <div className="absolute z-50 w-full bg-custom-white border-b border-black">
            <div className="flex flex-col px-6">
                <ul className="font-ubuntu-medium text-base border-b-2 border-gray-800 flex flex-col gap-4 py-4">
                    <li>
                        <Anchor url={Routes.HOME.path()}>Inicio</Anchor>
                    </li>
                    <li>
                        <Anchor url={Routes.HOME.path()}>Quienes somos</Anchor>
                    </li>
                    <li>
                        <Anchor url={Routes.LIST_COURSES.path()}>Cursos</Anchor>
                    </li>
                    <li>
                        <Anchor url={Routes.HOME.path()}>Consultoría</Anchor>
                    </li>
                    <li>
                        <Anchor url={Routes.BLOG.path()}>Blog</Anchor>
                    </li>
                    <li>
                        <Anchor url={Routes.HOME.path()}>Contacto</Anchor>
                    </li>
                    <li>
                        <Anchor url={Routes.NEWSLETTER.path()}>
                            NewsLetter
                        </Anchor>
                    </li>
                </ul>
            </div>
            <div className="px-6 py-4">
                {currentUser ? (
                    <div className="flex flex-col gap-4">
                        {currentUser?.permissions.includes(
                            Permission.VIEW_ADMIN_DASHBOARD,
                        ) && (
                            <Anchor
                                url={Routes.ADMIN_LIST_COURSES.path()}
                                className="font-ubuntu-medium text-base text-custom-blue"
                            >
                                Panel de administración
                            </Anchor>
                        )}
                        <Anchor
                            url={Routes.PROFILE.path(currentUser.id)}
                            className="font-ubuntu-medium text-base text-custom-blue"
                        >
                            Perfil
                        </Anchor>
                        <Anchor
                            url={Routes.LIST_MY_COURSE.path()}
                            className="font-ubuntu-medium text-base text-custom-blue"
                        >
                            Mis Cursos
                        </Anchor>
                        <button
                            type="button"
                            className="font-ubuntu-medium text-base text-custom-blue flex"
                            onClick={onLogOut}
                        >
                            Log Out
                        </button>
                    </div>
                ) : (
                    <Anchor
                        url={Routes.LOGIN.path()}
                        className="font-ubuntu-medium text-base text-custom-blue px-6 py-4"
                    >
                        Log In
                    </Anchor>
                )}
            </div>
        </div>
    );
}
