export class TaggedError extends Error {
    tag;
    constructor(name) {
        super();
        this.tag = name;
    }
    toString() {
        return this.tag;
    }
}
