export function serializeError(error) {
    return JSON.stringify({
        ...error,
    });
}
export function deserializeError(errorClasses, serializedError) {
    const error = new errorClasses[serializedError.tag]();
    Object.assign(error, serializedError);
    return error;
}
