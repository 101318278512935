/* eslint-disable @typescript-eslint/no-dynamic-delete */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MINUTE_TO_MS } from "../time/time-constants.js";
import { JSONUtils } from "../utils/json-utils.js";
export class CacheProvider {
    cache = {};
    get(key) {
        if (!this.cache[key]) {
            return undefined;
        }
        const cacheItem = this.cache[key];
        if (Date.now() - cacheItem.timestamp > cacheItem.maxAgeInMs) {
            delete this.cache[key];
            return undefined;
        }
        return cacheItem.data;
    }
    set(key, value, opts) {
        this.cache[key] = {
            timestamp: Date.now(),
            data: value,
            ...DefaultCacheOptions,
            ...opts,
        };
    }
    serialize() {
        return JSON.stringify(this.cache);
    }
    deserialize(serializedCache) {
        this.cache = JSONUtils.parse(serializedCache);
    }
    withCache(fn, key, opts) {
        const cachedValue = this.get(key);
        if (cachedValue && !opts?.forceRefresh) {
            return cachedValue;
        }
        const newValue = fn();
        this.set(key, newValue, opts);
        return newValue;
    }
    async withAsyncCache(fn, key, opts) {
        const cachedValue = this.get(key);
        if (cachedValue && !opts?.forceRefresh) {
            return cachedValue;
        }
        const newValue = await fn();
        this.set(key, newValue, opts);
        return newValue;
    }
}
export const DefaultCacheOptions = {
    maxAgeInMs: 10 * MINUTE_TO_MS,
};
