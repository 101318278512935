import { isError } from "@for-it/domain-lib";
import { Anchor, useCurrentUser, useLogout } from "@for-it/web-lib";
import { Permission } from "app-domain/src/auth/permissions.ts";
import { useState } from "react";
import { Routes } from "../../routes.tsx";
import { useNavigate } from "../../utils/routing-hooks.ts";
import { Menu } from "../menu.tsx";

export function Header() {
    const currentUser = useCurrentUser();
    const [openMenu, setOpenMenu] = useState(false);
    const [openProfileMenu, setOpenProfileMenu] = useState(false);

    const logOut = useLogout();
    const navigate = useNavigate();

    async function onLogOut() {
        const result = await logOut();
        if (isError(result)) {
            return result;
        } else {
            navigate.toUrl(Routes.HOME.path());
        }
    }

    return (
        <>
            <div className="border-b border-black">
                <div
                    className={`${openMenu ? "bg-custom-white" : "bg-custom-blue"} flex justify-center `}
                >
                    <div className=" container">
                        <div className="flex flex-row items-center justify-between py-2 px-8">
                            <Anchor url={Routes.HOME.path()}>
                                <img
                                    className={`${openMenu ? "w-8" : "w-28"}`}
                                    src={`${openMenu ? "/page-landing/isotipoEstacion.svg" : "/page-landing/logoEstacion.svg"}`}
                                    alt=""
                                />
                            </Anchor>
                            <div className="flex gap-2 items-center">
                                <ul className="font-ubuntu-medium text-base hidden lg:flex flex-row text-custom-white gap-5">
                                    <li>
                                        <Anchor url={Routes.HOME.path()}>
                                            Home
                                        </Anchor>
                                    </li>
                                    <li>
                                        <Anchor url={Routes.ABOUT_US.path()}>
                                            Quienes Somos
                                        </Anchor>
                                    </li>
                                    <li>
                                        <Anchor
                                            url={Routes.LIST_COURSES.path()}
                                        >
                                            Cursos
                                        </Anchor>
                                    </li>
                                    <li>
                                        <Anchor url={Routes.CONSULTANCY.path()}>
                                            Consultoría
                                        </Anchor>
                                    </li>
                                    <li>
                                        <Anchor url={Routes.BLOG.path()}>
                                            Blog
                                        </Anchor>
                                    </li>
                                    <li>
                                        <Anchor url={Routes.CONTACT.path()}>
                                            Contacto
                                        </Anchor>
                                    </li>
                                    <li>
                                        <Anchor url={Routes.NEWSLETTER.path()}>
                                            Newsletter
                                        </Anchor>
                                    </li>
                                </ul>
                                <div className="flex gap-5">
                                    {currentUser ? (
                                        <button
                                            onClick={() => {
                                                setOpenProfileMenu(
                                                    !openProfileMenu,
                                                );
                                            }}
                                            type="button"
                                            className={`font-ubuntu-medium text-sm px-5 py-2 text-white hidden lg:block`}
                                        >
                                            <img
                                                src="/page-landing/account.svg"
                                                alt="Mi perfil"
                                                className="w-9 border border-white rounded-full p-2"
                                            />
                                        </button>
                                    ) : (
                                        <Anchor
                                            url={Routes.LOGIN.path()}
                                            className="font-ubuntu-medium text-base border border-white rounded-xl justify-center text-white w-20 h-10"
                                        >
                                            <p>Log In</p>
                                        </Anchor>
                                    )}
                                    <button
                                        type="button"
                                        className="lg:hidden"
                                        onClick={() => setOpenMenu(!openMenu)}
                                    >
                                        <img
                                            className="w-5"
                                            src={`${openMenu ? "/page-landing/burgerMenuBlue.svg" : "/page-landing/burgerMenu.svg"}`}
                                        />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-end pr-10">
                            <div
                                className={`border w-40 rounded-2xl py-3 shadow-md absolute top-16 z-50 bg-custom-white  ${currentUser && openProfileMenu ? "hidden lg:block" : "hidden"}`}
                            >
                                <div className="flex flex-col gap-1">
                                    {currentUser?.permissions.includes(
                                        Permission.VIEW_ADMIN_DASHBOARD,
                                    ) && (
                                        <Anchor
                                            url={Routes.ADMIN_LIST_COURSES.path()}
                                            className="font-ubuntu-medium text-base text-custom-blue px-4 hover:bg-slate-200"
                                        >
                                            Panel de administración
                                        </Anchor>
                                    )}
                                    <Anchor
                                        url={Routes.PROFILE.path(
                                            currentUser?.id,
                                        )}
                                        className="font-ubuntu-medium text-base text-custom-blue px-4 hover:bg-slate-200"
                                    >
                                        Perfil
                                    </Anchor>
                                    <Anchor
                                        url={Routes.LIST_MY_COURSE.path()}
                                        className="font-ubuntu-medium text-base text-custom-blue px-4 hover:bg-slate-200"
                                    >
                                        Mis Cursos
                                    </Anchor>
                                    <button
                                        type="button"
                                        className="font-ubuntu-medium text-base text-custom-blue flex px-4 hover:bg-slate-200"
                                        onClick={onLogOut}
                                    >
                                        Log Out
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {openMenu ? <Menu /> : null}
            </div>
        </>
    );
}
