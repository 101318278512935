/**
 * Number of milliseconds in a second.
 *
 * 1000
 */
export const SECOND_TO_MS = 1_000;
/**
 * Number of milliseconds in a minute.
 *
 * 60 * 1000
 */
export const MINUTE_TO_MS = 60_000;
/**
 * Number of milliseconds in an hour.
 *
 * 60 * 60 * 1000
 */
export const HOUR_TO_MS = 3_600_000;
